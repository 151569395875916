<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="shower"
        max-width="520px"
        persistent
        scrollable
      >
        <v-card scrollable persistent>
          <v-card-title>
            <v-layout>
              <v-flex lg11 v-if="resolutionScreen >= 400">
                {{ $t("requestdownload.setting_request") }}
              </v-flex>
              <v-flex lg11 xs10 v-else>
                {{ $t("requestdownload.setting_request").substring(0, 20) }}
              </v-flex>
              <v-flex lg1 xs2 class="text-right">
                <!-- <v-icon :color="color.theme" dense dark size="35"> -->
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>file-cog</title><path d="M6 2C4.89 2 4 2.89 4 4V20A2 2 0 0 0 6 22H12.68A7 7 0 0 1 12 19A7 7 0 0 1 19 12A7 7 0 0 1 20 12.08V8L14 2H6M13 3.5L18.5 9H13V3.5M18 14C17.87 14 17.76 14.09 17.74 14.21L17.55 15.53C17.25 15.66 16.96 15.82 16.7 16L15.46 15.5C15.35 15.5 15.22 15.5 15.15 15.63L14.15 17.36C14.09 17.47 14.11 17.6 14.21 17.68L15.27 18.5C15.25 18.67 15.24 18.83 15.24 19C15.24 19.17 15.25 19.33 15.27 19.5L14.21 20.32C14.12 20.4 14.09 20.53 14.15 20.64L15.15 22.37C15.21 22.5 15.34 22.5 15.46 22.5L16.7 22C16.96 22.18 17.24 22.35 17.55 22.47L17.74 23.79C17.76 23.91 17.86 24 18 24H20C20.11 24 20.22 23.91 20.24 23.79L20.43 22.47C20.73 22.34 21 22.18 21.27 22L22.5 22.5C22.63 22.5 22.76 22.5 22.83 22.37L23.83 20.64C23.89 20.53 23.86 20.4 23.77 20.32L22.7 19.5C22.72 19.33 22.74 19.17 22.74 19C22.74 18.83 22.73 18.67 22.7 18.5L23.76 17.68C23.85 17.6 23.88 17.47 23.82 17.36L22.82 15.63C22.76 15.5 22.63 15.5 22.5 15.5L21.27 16C21 15.82 20.73 15.65 20.42 15.53L20.23 14.21C20.22 14.09 20.11 14 20 14H18M19 17.5C19.83 17.5 20.5 18.17 20.5 19C20.5 19.83 19.83 20.5 19 20.5C18.16 20.5 17.5 19.83 17.5 19C17.5 18.17 18.17 17.5 19 17.5Z" /></svg> -->
                  <!-- </v-icon> -->
                  </v-flex>
            </v-layout>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text style="height:440px" class="pt-4">
            <v-flex>
              <v-switch
                v-model="settingdownload"
                :label="settingdownload == true ?  $t('requestdownload.opensetting')  : $t('requestdownload.canclesetting')"
                hide-details
                inset
              ></v-switch>
            </v-flex>
            <div v-if="settingdownload == true" class="mt-6">
              <v-flex class="mt-2">
                <v-text-field
                  clearable
                  outlined
                  dense
                  v-model="email"
                  :label="$t('default') === 'th' ? 'อีเมล' : 'E-mail'"     
                  required
                  prepend-icon="email"
                  :rules="$t('default') === 'th' ? requiredemail : requiredemail_eng"
                  :error-messages="$t('default') === 'th' ? emailError : emailError_eng"
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                    ></v-text-field>
              </v-flex>
              <v-layout row wrap justify-center class="mt-n5">
                    <v-flex lg11 xs11>
                      <v-switch
                        v-model="switch1"
                        inset
                        :label="$t('sharefile.settime')"
                      ></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center>
                    <v-flex lg5 xs11 v-if="switch1 === true">
                      <v-dialog
                        persistent
                        v-model="modal"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            clearable
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                            v-model="picker"
                            :label="$t('sharefile.setdate')"
                            readonly
                            prepend-icon="mdi-calendar"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="picker"
                          :min="datenow"
                          @input="modal = false"
                        >
                          <v-spacer></v-spacer>
                          <v-btn dark color="error" @click="modal = false">
                            {{ $t("sharefile.closedatepiker") }}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <v-flex lg3 xs6 v-if="switch1 === true">
                      <v-select
                        class="pl-1"
                        :label="$t('sharefile.sethours')"
                        outlined
                        dense
                        :items="hours"
                        v-model="hour"
                      >
                      </v-select>
                    </v-flex>
                    <v-flex lg3 xs5 v-if="switch1 === true">
                      <v-select
                        class="pl-1"
                        :label="$t('sharefile.setminute')"
                        outlined
                        dense
                        :items="minutes"
                        v-model="minute"
                      >
                      </v-select>
                    </v-flex>
                  </v-layout>
            </div>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              class="white--text"
              @click="close_fn()"
              >  {{ $t("requestdownload.close") }}
            </v-btn>
            <!-- <v-btn
              v-if="settingdownload == true"
              :color="color.theme"
              class="white--text"
              @click="editapi()"
              :loading="processloading"
              >{{ $t("requestdownload.canclesetting") }}
            </v-btn> -->
            <v-btn
              v-if="settingdownload == true" 
              :color="color.theme"
              class="white--text"
              @click="fn_setting_request()"
              :loading="processloading"
              >{{ $t("requestdownload.setting_btn") }}
            </v-btn>
            <v-btn
              v-else
              :color="color.theme"
              class="white--text"
              @click="fn_setting_close_request()"
              :loading="processloading"
              >{{ $t("requestdownload.setting_btn") }}
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-row>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import { log } from 'console';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: ["show", "filedata"],
  mixins: [validationMixin],
  validations: {
    email: { required },
  },
  components: { },
  data: function() {
    return {
      timerequest:"",
      switch1:false,
      processloading:false,
      settingdownload:false,
      modal: false,
      email:"",
      hours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      minutes: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
      picker: "",
      requiredemail: [
        (value) => !!value || "โปรดระบุข้อมูล.", 
        (value) => /.+@.+\..+/.test(value) || "ข้อมูลไม่ถูกต้อง"
      ],
      requiredemail_eng: [
        (value) => !!value || "Please fill data", 
        (value) => /.+@.+\..+/.test(value) || "Please fill Email"
      ],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    emailError() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("โปรดระบุอีเมล");
      return errors;
    },
    emailError_eng() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Please fill Email");
      return errors;
    },
    shower: {
      get() {

        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },

  },
  watch: {
    show: function(val) {
      if (val === true) {
        this.fn_check_requestdownload();
      }
    },
  },
  methods: {
    close_fn(){
      this.$emit("closedialog");
    },
    async fn_check_requestdownload(){
      this.email = '';
      let auth = await gbfGenerate.generateToken();
      console.log("this.filedata",this.filedata);
          let payload = {
            data_id: this.filedata.file_id,
            account_id: this.dataAccountId,
            data_type: this.filedata.file_type
          };
          console.log("payload", payload);
          this.axios
            .post(
              process.env.VUE_APP_SERVICE_DOWNLOAD_API +
                "/api/get/rerquest_downloads",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              console.log(response);
              if (response.data.status === "OK") {
                console.log("status",response.data.result.status);
                if(response.data.result.status === 'N'){
                  this.settingdownload = false;
                }else{
                  this.settingdownload = true;
                  this.email = response.data.result.email_owner
                  this.format_date(response.data.result.time_approve);
                }

                if(response.data.result.status_approve === 'N'){
                  this.switch1 = false;
                }else{
                  this.switch1 = true;
                  this.timerequest = response.data.result.status_approve

                }
              }
            });
    },
    async fn_setting_request(){
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if(this.switch1 == true){
          if(this.picker === '' || this.hour === '' || this.minute === ''){
            Toast.fire({
                icon: "error",
                title: "กรุณากรอกข้อมูลเวลาที่ต้องการตั้งค่าดาวน์โหลก",
              });
          }else{
            console.log("this.modal",this.modal);
            console.log("this.picker",this.picker);
            console.log("this.hour",this.hour);
            console.log("this.minute",this.minute);

            let datesplit = this.picker.split("-")
            let date = datesplit[0] + datesplit[1] + datesplit[2] + this.hour + this.minute + "00"
            this.timerequest = date;

            console.log("this.timerequest",this.timerequest);
   
          }
         
        }else{
          this.timerequest = "00000000000000"

        }
      let payload = {
        account_id: this.dataAccountId,
        data_id: this.filedata.file_id,
        data_type: this.filedata.file_type,
        status_approve: this.switch1 == true ? "Y" : "N",
        time_approve: this.timerequest,
        email_owner: this.email,
        status: "Y"
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
            .post(
              process.env.VUE_APP_SERVICE_DOWNLOAD_API +
                "/api/setting/rerquest_downloads",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              console.log(response);
              if (response.data.status === "OK") {
                Toast.fire({
                  icon: "success",
                  title: "ตั้งค่าคำขอสำเร็จ",
                });
                // this.fn_check_requestdownload();
              }else{
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                });
              }
            });
      }
    },

    async fn_setting_close_request(){
      let payload = {
        account_id: this.dataAccountId,
        data_id: this.filedata.file_id,
        data_type: this.filedata.file_type,
        status_approve: "N",
        time_approve: "00000000000000",
        email_owner: this.email,
        status: "N"
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
            .post(
              process.env.VUE_APP_SERVICE_DOWNLOAD_API +
                "/api/setting/rerquest_downloads",
              payload,
              {
                headers: { Authorization: auth.code },
              }
            )
            .then((response) => {
              console.log(response);
              if (response.data.status === "OK") {
                Toast.fire({
                  icon: "success",
                  title: "ปิดการตั้งค่าคำขอสำเร็จ",
                });
                // this.fn_check_requestdownload();
              }else{
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage,
                });
              }
            });
    },

    format_date(_datetime) {
      let dateyear_ =
        _datetime.split("")[0] +
        _datetime.split("")[1] +
        _datetime.split("")[2] +
        _datetime.split("")[3];
      let datemonth_ = _datetime.split("")[4] + _datetime.split("")[5];
      let dateday_ = _datetime.split("")[6] + _datetime.split("")[7];
      let hour_ = _datetime.split("")[8] + _datetime.split("")[9];
      let minute_ = _datetime.split("")[10] + _datetime.split("")[11];
      let second_ = _datetime.split("")[12] + _datetime.split("")[13];

       this.picker = dateyear_ + '-' + datemonth_ + '-' + dateday_;
       this.hour = hour_;
       this.minute = minute_
    },

    // formatdatetime(_datetime) {
    //   let dateyear =
    //     _datetime.split("")[0] +
    //     _datetime.split("")[1] +
    //     _datetime.split("")[2] +
    //     _datetime.split("")[3];
    //   let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
    //   let dateday = _datetime.split("")[6] + _datetime.split("")[7];
    //   let hour = _datetime.split("")[8] + _datetime.split("")[9];
    //   let minute = _datetime.split("")[10] + _datetime.split("")[11];
    //   let second = _datetime.split("")[12] + _datetime.split("")[13];

    //   return (
    //     dateday +
    //     "/" +
    //     datemonth +
    //     "/" +
    //     dateyear +
    //     " " +
    //     hour +
    //     ":" +
    //     minute +
    //     ":" +
    //     second
    //   );
    // },
    // setformatdate(_date) {
    //   let data =
    //     _date.substring(8, 6) +
    //     "/" +
    //     _date.substring(6, 4) +
    //     "/" +
    //     _date.substring(0, 4);
    //   return data || "-";
    // },
  },
};
</script>
<style>
#thiscontainer2 {
  height: calc(101vh - 400px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>

